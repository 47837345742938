import { useContext } from "react";
import { Button } from "@chakra-ui/react";
import { AuthContext } from "../../services/auth/authContext";
import { Link, useNavigate } from 'react-router-dom';

interface LoginTypes {
  onLogin: () => void,
  isLoading?: boolean
}

export const LoginButton = ({ onLogin, isLoading }: LoginTypes) => (
  <Button colorScheme="blue" onClick={onLogin} isLoading={isLoading} size="lg">
    Login
  </Button>
);

export const LogoutButton = () => {
  const { logOutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Button
      colorScheme="orange"
      onClick={() => {
        logOutUser && logOutUser() 
        navigate('/', {})
      }}
    >
      <Link to="/">
        Logout
      </Link>
    </Button>
  );
};