import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginView from "./Login";
import TopBar from "../components/common/TopBar";
import PasswordReset from "../components/coreUsers/PasswordReset";
import PasswordInstructions from "./PasswordInstructions";

const Anonymous = () => {
  return (
        <Box height="100vh" p={8} width="100%">
            <Router>
                <TopBar />            
                
                <Routes>
                    <Route path="/login" element={<LoginView />} />
                    {/* <Route path="/passwordinstructions" element={<PasswordInstructions />}/>
                    <Route path="/passwordreset/:token" element={<PasswordReset />}/> */}
                    <Route path="/" element={<LoginView />} />
                </Routes>
            </Router>
        </Box>
  );
};

export default Anonymous;