import {
  Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
  } from "@chakra-ui/react";
import { Subtitle } from "../common/Texts";
import { useNavigate } from 'react-router-dom';


  interface OwnTypes {
    users:{
      helpilepsy: Array<Record<string,any>>,
      migraine: Array<Record<string,any>>
    }
  }
  
  const NeuroventisUsersTable = ({ users }:OwnTypes) => {
    const navigate = useNavigate();
    const handleClick = (user:Record<string,any>) => navigate('/neuroventis_single_user', {state:{user}});

    return (
      <>
        <Subtitle>Helpilepsy users</Subtitle>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Full Name</Th>
                <Th>Created At</Th>
                <Th>Role</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.helpilepsy.map((user) => (
                <Tr key={user.id} _hover={{ bg: 'gray.200' }} cursor={'pointer'} onClick={() => handleClick(user)}>
                  <Td>{user.email}</Td>
                  <Td>{user?.firstName +' '+ user?.lastName}</Td>
                  <Td>{user.createdAt}</Td>
                  <Td>{user.role}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Spacer/>
        <Subtitle>Migraine users</Subtitle>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Full Name</Th>
                <Th>Created At</Th>
                <Th>Role</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.migraine.map((user) => (
                <Tr key={user.id} _hover={{ bg: 'gray.200' }} cursor={'pointer'} onClick={() => handleClick(user)}>
                  <Td>{user.email}</Td>
                  <Td>{user?.firstName +' '+ user?.lastName}</Td>
                  <Td>{user.createdAt}</Td>
                  <Td>{user.role}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };
  
  export default NeuroventisUsersTable;  