import {
    StatGroup,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText
} from '@chakra-ui/react';
import { faMobileAlt, faPeopleGroup, faCheckCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppVersion from './AppVersion';
import CountryStats from './CountryStats';

interface OwnTypes {
    statistics: {
        appInstalled: number,
        appInstalledActive: number,
        patientsAppVersion: Array<any>,
        patientsAppVersionActive: Array<any>,
        patientsCountry: Array<any>,
        patientsCountryActive: Array<any>,
        totalPatients: number,
        totalPatientsActive: number
    }
}

const PatientStatistics = ({statistics}:OwnTypes) => {
    return(
        <>
            <StatGroup>
                <Stat textAlign={'center'}>
                    <StatLabel>App Installed</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faMobileAlt} /> {statistics?.appInstalled}</StatNumber>
                </Stat>

                <Stat textAlign={'center'}>
                    <StatLabel>App Installed & Active</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faCheckCircle} color='green' />  {statistics?.appInstalledActive}</StatNumber>
                    <StatHelpText>
                        <FontAwesomeIcon icon={faChartLine} color='orange' /> {((statistics?.appInstalledActive / statistics?.appInstalled) * 100).toFixed(2)} %
                    </StatHelpText>
                </Stat>
            </StatGroup>
            <StatGroup>
                <Stat textAlign={'center'}>
                    <StatLabel>Total patients</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faPeopleGroup} /> {statistics?.totalPatients}</StatNumber>
                </Stat>

                <Stat textAlign={'center'}>
                    <StatLabel>Total active patients</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faCheckCircle} color='green' /> {statistics?.totalPatientsActive}</StatNumber>
                    <StatHelpText>
                        <FontAwesomeIcon icon={faChartLine} color='orange' /> {((statistics?.totalPatientsActive / statistics?.totalPatients) * 100).toFixed(2)} %
                    </StatHelpText>
                </Stat>
            </StatGroup>
            <AppVersion type={'Patients'} data={statistics?.patientsAppVersion} total={statistics?.totalPatients}/>
            <AppVersion type={'Active patients'} data={statistics?.patientsAppVersionActive} total={statistics?.totalPatientsActive}/>
            <CountryStats type={'Patients'} data={statistics?.patientsCountry} total={statistics?.totalPatients}/>
            <CountryStats type={'Active patients'} data={statistics?.patientsCountryActive} total={statistics?.totalPatientsActive}/>
        </>
    )
}

export default PatientStatistics;