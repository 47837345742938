import { Center, Spinner, InputGroup, Input, InputRightElement} from "@chakra-ui/react";
import NeuroventisUsersTable from "../components/neuroventisUsers/NeuroventisUsersTable";
import { ErrorText, NoteText } from "../components/common/Texts";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { get } from "../api/base";

const NeuroventisStudySingleUser = () => {
    const [users, setUsers] = useState<any>({helpilepsy:[], migraine:[]});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);

    let timeout:any = 0;

    const search = (e:React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            if(e.target.value.length > 0){
                get(`/integration/users/retrieveUsers?search=${e.target.value}`)
                .then((response) => {
                    if (response.status === 200 ) {
                        const { data } = response.data;
                        setUsers(data);
                        setLoading(false)
                        setError(null)
                    }
                })
                .catch((err) => {
                    console.error("Error getting users", err.message);
                    setError(err.message)
                    setLoading(false)
                });
            } else {
                setLoading(false)
                setError(null)
                setUsers({helpilepsy:[], migraine:[]})
            }
            
        }, 1000)
    }
    
    return (
        <>
            <InputGroup>
                
                <Input 
                    placeholder='Search for a study patient'
                    onChange={search}
                />
                <InputRightElement
                    pointerEvents='none'
                    children={<FontAwesomeIcon color="grey" icon={faSearch} />}
                />
            </InputGroup>
            { loading && <Center> <Spinner /> </Center>}
            {!loading && !error && (users.helpilepsy.length > 0 || users.migraine.length > 0) && <NeuroventisUsersTable users={users} /> }
            {!loading && !error && (users.helpilepsy.length === 0 && users.migraine.length === 0) && <Center><NoteText>No result</NoteText></Center> }
            {!loading && error && <Center><ErrorText>Something went wrong!</ErrorText></Center>}
        </>
    );
};

export default NeuroventisStudySingleUser;