import {
    Box,
    Button,
    ButtonGroup,
    VStack,
    Divider
  } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

  
  interface Props {
    onClose: () => void
    isOpen: boolean
    variant: 'drawer' | 'sidebar'
  }
  
  const SidebarContent = () => {
    const navigate = useNavigate();
    const roles = JSON.parse(localStorage.getItem('roles')!);

    return(
      <>
          <VStack>
            <Divider orientation='horizontal' />
            <ButtonGroup w="100%" >
                <Button w="100%" onClick={() => navigate('/')} colorScheme='orange' variant='ghost'>
                    My Profile
                </Button>
            </ButtonGroup>
            <Divider orientation='horizontal' />
           {
             ['admin', 'manager', 'product', 'clinops'].some(str => roles?.includes(str)) &&
             <>
                 <Divider orientation='horizontal' />
                <ButtonGroup w="100%">
                    <Button w="100%" onClick={() => navigate('/neuroventis_users')} colorScheme='orange' variant='ghost'>
                    User Lookup
                    </Button>
                </ButtonGroup>
                <Divider orientation='horizontal' />
             </>
           }
           
            {
              ['admin', 'manager', 'product', 'growth'].some(str => roles?.includes(str)) &&
              <>
                <Divider orientation='horizontal' />
                <ButtonGroup w="100%">
                    <Button w="100%" onClick={() => navigate('/statistics')} colorScheme='orange' variant='ghost'>
                        Statistics
                    </Button>
                </ButtonGroup>
                <Divider orientation='horizontal' />

              </>
            }

            {
              ['admin', 'clinOps', 'product', 'growth'].some(str => roles?.includes(str)) &&
              <>
                <Divider orientation='horizontal' />
                <ButtonGroup w="100%">
                    <Button w="100%" onClick={() => navigate('/exports')} colorScheme='orange' variant='ghost'>
                        Data Exports
                    </Button>
                </ButtonGroup>
                <Divider orientation='horizontal' />

              </>
            }
            {
    
              ['admin', 'manager', 'hr'].some(str => roles?.includes(str)) &&
              <>
                <Divider orientation='horizontal' />
                <ButtonGroup w="100%">
                  <Button w="100%" onClick={() => navigate('/core_users')} colorScheme='blue' variant='ghost'>
                    Core users
                  </Button>
                </ButtonGroup>
                <Divider orientation='horizontal' />
              </>
            }
            {/* {
              ['admin'].some(str => roles?.includes(str)) &&
              <>
                <Divider orientation='horizontal' />
                <ButtonGroup w="100%">
                  <Button  w="100%" onClick={() => navigate('/study_single_user')} colorScheme='blue' variant='ghost'>
                    Admin Panel
                  </Button>
                </ButtonGroup>
                <Divider orientation='horizontal' />

              </>
              
            } */}
        </VStack>
      </>
      
    )
  }
  
  const Sidebar = ({ isOpen, variant, onClose }: Props) => {
    return (
      <Box
        // position="fixed"
        // left={0}
        // p={5}
        w="15%"
        // top={0}
        h="100%"
        // bg="#dfdfdf"
      >
        <SidebarContent/>
      </Box>
    ) 
  }
  
  export default Sidebar