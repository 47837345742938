import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";
import { useState } from "react";

interface OwnTypes {
  valuesRef: {
    current:Array<any>
  }
}

const roles = [
  {label: 'Manager', value: 'manager'},
  {label: 'Growth', value: 'growth'},
  {label: 'ClinOps', value: 'clinops'},
  {label: 'QARA', value: 'qara'},
  {label: 'HR', value: 'hr'},
  {label: 'Customer Support', value: 'customer_support'},
  {label: 'Product', value: 'product'},
]

const InvitationForm = ({ valuesRef }:OwnTypes) => {

  const [value, setValue] = useState<any>();

  return (
    <>
      <FormControl>
        <FormLabel>Email Address</FormLabel>
        <Input
          type="email"
          onChange={(e) => {
            valuesRef.current[0] = e.target.value;
          }}
        />
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          onChange={(e) => {
            valuesRef.current[1] = e.target.value;
          }}
        />
        <FormLabel>Firstname</FormLabel>
        <Input
          type="string"
          onChange={(e) => {
            valuesRef.current[2] = e.target.value;
          }}
        />
        <FormLabel>Lastname</FormLabel>
        <Input
          type="string"
          onChange={(e) => {
            valuesRef.current[3] = e.target.value;
          }}
        />
        <FormLabel>Phone</FormLabel>
        <Input
          type="number"
          onChange={(e) => {
            valuesRef.current[4] = e.target.value;
          }}
        />
        <FormLabel>Gender</FormLabel>
        <Select placeholder='Select option' onChange={(e) => {
          valuesRef.current[5] = e.target.value;
        }}>
          <option value='male'>Male</option>
          <option value='female'>Female</option>
          <option value='other'>Other</option>
        </Select>
        <MultiSelect
          options={roles}
          value={value}
          label='Roles'
          onChange={(selectedOptions: any) => {
            let values;
            if (Array.isArray(selectedOptions)) {
              values = selectedOptions.map(option => {
                if (typeof option === 'object' && option !== null && 'value' in option) {
                  return option.value;
                } else {
                  return option;
                }
              });
            } else {
              values = [selectedOptions];
            }
            setValue(values);
            valuesRef.current[6] = values;
          }}
        />
       
      </FormControl>
    </>
  );
};

export default InvitationForm;