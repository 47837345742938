import { createContext, useState, useMemo, useCallback } from "react";
import { fetchStatistics } from ".";

interface ContextTypes {
  isLoading: boolean, 
  error: Record<string, boolean | string>,
  patientStatistics: Record<string, Record<string, any>>,
  doctorStatistics: Record<string, Record<string, any>>,
  fetchPatientStatistics: () => void,
  fetchDoctorStatistics: () => void
}

export const StatisticsContext = createContext<Partial<ContextTypes>>({});

export const StatisticsProvider = ({ children }:any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Record<string, boolean | string>>({ hasError: false, message: "" });
    const [patientStatistics, setPatientStatistics] = useState<Record<string, Record<string, any>>>();
    const [doctorStatistics, setDoctorStatistics] = useState<Record<string, Record<string, any>>>();

    const fetchPatientStatistics = useCallback ( async () => {
        setError({ hasError: false, message: "" });
        setIsLoading(true);
        const patientStatisticsResult = await fetchStatistics('/integration/users/patientStatistics');
        setPatientStatistics(patientStatisticsResult?.data)
        setIsLoading(false);
    }, [])

    const fetchDoctorStatistics = useCallback ( async () => {
      setError({ hasError: false, message: "" });
      setIsLoading(true);
      const doctorStatisticsResult = await fetchStatistics('/integration/users/doctorStatistics');
      setDoctorStatistics(doctorStatisticsResult?.data)
      setIsLoading(false);
  }, [])

    const statisticsProviderValue = useMemo(() => ({  isLoading, error, patientStatistics, doctorStatistics, fetchPatientStatistics, fetchDoctorStatistics }), [ isLoading, error, patientStatistics, doctorStatistics, fetchPatientStatistics, fetchDoctorStatistics]);

    return (
      <StatisticsContext.Provider value={statisticsProviderValue}>
        {children}
      </StatisticsContext.Provider>
    )
}