import { requestLogin, requestMe } from "../../api/auth";

export const loginEmailPassword = async (email:string, password:string) => {
    try {
        return await requestLogin(email, password);
    } catch (err:any) {
        if (err.response.status === 401)
            return err.response.data
        else
            return null
    }
};

export const logoutCurrentUser = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem('userId');
    localStorage.removeItem('roles');
    return true;
};


export const fetchCurrentUser = async (id:string) => {
    try {
        const user = await requestMe(id);
        return user;
    } catch (err:any) {
        console.error("Error fetching current user", err.message);
        return null;
    }
};
