import { generalRequest } from "../../api/auth";

export const fetchStatistics = async (path:string) => {
    try {
        const statistics = await generalRequest(path, 'get');
        return statistics;
    } catch (err:any) {
        console.error("Error fetching current user", err.message);
        return null;
    }
};