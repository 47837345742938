import axios from "axios";
import env from '../env.json';

const {REACT_APP_SERVER_URL} = env;

export const requestLogin = async (email:string, password:string) => {
    const response = await axios.post(
        `${REACT_APP_SERVER_URL}/core/auth/login`,
        { email, password },
        { withCredentials: true }
    );
    if (response.status !== 201 && response.status !== 401) {
        return null;
    }
    return response.data;
};

export const requestLogout = async () => {
    const response = await axios.post(
        `${REACT_APP_SERVER_URL}/users/logout`,
        null,
        { withCredentials: true }
    );

    if (response.status !== 200) {
        return null;
    }

    return response.data;
};

export const requestMe = async (id:string) => {
    const response = await axios.get(
        `${REACT_APP_SERVER_URL}/core/users/singleUser/${id}`,
        { 
            withCredentials: true, 
            headers: { 
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json' 
            } 
        }
    );
    if (response.status !== 200) {
        return null;
    }

    return response.data;
}

export const generalRequest = async (path:string, method:string, data?:any) => {
    const response = await axios({
        method,
        url: `${REACT_APP_SERVER_URL}${path}`,
        data,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json' 
        }
    })
    if (response.status !== 200) {
        return null;
    }

    return response.data;
}