import { useContext } from "react";
import { StatisticsContext } from "../services/statistics/statisticsContext";
import {
    Flex,
    Center,
    Spinner,
    Tabs, 
    TabList, 
    Tab,
    TabPanels, 
    TabPanel
  } from '@chakra-ui/react';
import { ErrorText } from "../components/common/Texts";
import PatientStatistics from "../components/statistics/PatientStatistics";
import DoctorStatistics from "../components/statistics/DoctorStatisics";
import { useLegacyEffect } from "../utils";

const Statistics = () => {
    const { 
        error, 
        isLoading, 
        patientStatistics, 
        doctorStatistics, 
        fetchPatientStatistics, 
        fetchDoctorStatistics 
    } = useContext(StatisticsContext);

    useLegacyEffect(() => {
        if(!patientStatistics)
            fetchPatientStatistics && fetchPatientStatistics()
    }, [])

    const onClick = (type:string) => {
        if(!patientStatistics && type === 'patient')
            fetchPatientStatistics && fetchPatientStatistics()
        else if(!doctorStatistics && type === 'doctor')
            fetchDoctorStatistics && fetchDoctorStatistics()
    }

    return (
        <>
            <Flex alignSelf='center' justifyContent='center' marginBottom={10}>
            <Tabs variant='enclosed' defaultIndex={0} colorScheme='orange'>
                <TabList>
                    <Tab onClick={() => onClick('patient')}>Patients' Helpilepsy Statistics</Tab>
                    <Tab onClick={() => onClick('patient')}>Patients' Migraine Statistics</Tab>
                    <Tab onClick={() => onClick('doctor')}>Doctors' Helpilepsy Statistics</Tab>
                    <Tab onClick={() => onClick('doctor')}>Doctors' Migraine Statistics</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {!isLoading && patientStatistics &&
                            //@ts-ignore 
                            <PatientStatistics statistics={patientStatistics['helpilepsy']}/>
                        }
                    </TabPanel>
                    <TabPanel>
                        {!isLoading && patientStatistics &&
                            //@ts-ignore 
                            <PatientStatistics statistics={patientStatistics['migraine']}/>
                        }
                    </TabPanel>
                    <TabPanel>
                        {!isLoading && doctorStatistics &&
                            //@ts-ignore 
                            <DoctorStatistics statistics={doctorStatistics['helpilepsy']}/>
                        }
                    </TabPanel>
                    <TabPanel>
                        {!isLoading && doctorStatistics &&
                            //@ts-ignore 
                            <DoctorStatistics statistics={doctorStatistics['migraine']}/>
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>
            </Flex>
            {error?.hasError && 
                <ErrorText>{error.message}</ErrorText>
            }
            {isLoading &&
                <Center m='10'> <Spinner /> </Center>
            }
        </>
    )
}

export default Statistics;