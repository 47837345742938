import { Box, Image } from "@chakra-ui/react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../services/auth/authContext";
import { LogoutButton } from "../auth/Login";

const TopBar = () => {
  const { isTokenExists } = useContext(AuthContext);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Link to="/">
          <Image
            height="40px"
            src="/neuroventis_logo.png"
            alt="Neuroventis Logo"
          />
        </Link>
        <Box display="flex" alignItems="center">
          { isTokenExists && <LogoutButton /> }
        </Box>
      </Box>
      <hr style={{ borderWidth: "1px", marginTop: "20px" }} />
    </>
  );
};

export default TopBar;