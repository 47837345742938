import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
  import { formatTimestamp } from "../../utils";
  import { Subtitle } from "../common/Texts";
  import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

  interface OwnTypes {
    users:Array<Record<string,string>>
  }
  
  const InvitationsTable = ({ users }:OwnTypes) => {
    const navigate = useNavigate();
    const handleClick = (user:Record<string,any>) => navigate('/neuroventis_single_core_user', {state:{user}});

    return (
      <>
        <Subtitle>Invitations</Subtitle>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Activated</Th>
                <Th>Email</Th>
                <Th>Sent on</Th>
                <Th>Expires on</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id} _hover={{ bg: 'gray.200' }} cursor={'pointer'} onClick={() => handleClick(user)}>
                  <Td>{user.activated && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</Td>
                  <Td>{user.email}</Td>
                  <Td>{formatTimestamp(user.createdAt)}</Td>
                  <Td>{user?.registrationTokenExpiry && formatTimestamp(user.registrationTokenExpiry)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };
  
  export default InvitationsTable;  