import { Center, Spinner, InputGroup, Input, InputRightElement, Flex, IconButton, Alert, List, Text, ListItem, Button, Divider } from "@chakra-ui/react";
import NeuroventisUsersTable from "../components/neuroventisUsers/NeuroventisUsersTable";
import { EmphasisText, ErrorText, NoteText, Subtitle, Title } from "../components/common/Texts";
import { faDownload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { get, post } from "../api/base";

const DataExports = () => {

	const [message, setMessage] = useState<string | null>(null);
	const [studyId, setStudyId] = useState<string | null>(null);
	const [studyTwoId, setStudyTwoId] = useState<string | null>(null);
	const [hcpId, setHcpId] = useState<Array<string>>([]);
	const [siteId, setSiteId] = useState<string | null>(null);
	const [excludedSites, setExcludedSites] = useState<Array<string>>([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);


	const downloadFullStudy = async () => {
		setLoading(true)
		post(`/integration/study/full-export`, {
			studyId: studyId,
			excludedSites: excludedSites
		})
			.then((response) => {
				if (response.status === 201) {
					setMessage(response.data.message);
					setLoading(false)
					setError(null)
					setExcludedSites([]);
					setStudyId(null);
				}
			})
			.catch((err) => {
				console.error("Error Downloading study", err.message);
				setError(err.message)
				setExcludedSites([]);
				setStudyId(null);
				setLoading(false)
			});

	};

	const downloadSite = async () => {
		setLoading(true)
		post(`/integration/study/site-export`, {
			studyId: studyTwoId,
			siteId: siteId
		})
			.then((response) => {
				if (response.status === 201) {
					setMessage(response.data.message);
					setLoading(false)
					setError(null)
					setExcludedSites([]);
					setStudyId(null);
					setSiteId(null);
				}
			})
			.catch((err) => {
				console.error("Error Downloading study", err.message);
				setError(err.message)
				setExcludedSites([]);
				setStudyId(null);
				setSiteId(null);
				setLoading(false)
			});

	};

	const downloadHCP = async () => {
		setLoading(true)
		post(`/integration/study/hcp-export`, {
			doctors: hcpId
		})
			.then((response) => {
				if (response.status === 201) {
					setMessage(response.data.message);
					setLoading(false)
					setError(null)
					setExcludedSites([]);
					setHcpId([]);
					setStudyId(null);
					setSiteId(null);
				}
			})
			.catch((err) => {
				console.error("Error Downloading study", err.message);
				setError(err.message)
				setExcludedSites([]);
				setStudyId(null);
				setHcpId([]);
				setSiteId(null);
				setLoading(false)
			});

	};

	const handleKeyDownStudy = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			setStudyId(e.currentTarget.value);
		}
	};

	const handleKeyDownStudyTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStudyTwoId(e.currentTarget.value);
	};

	const handleKeyDownExclude = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const currentInputValue = e.currentTarget.value;
			setExcludedSites(prevSites => [...prevSites, currentInputValue]);
		}
	};

	const handleKeyDownSingle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSiteId(e.currentTarget.value);
	};
	const handleKeyDownHcp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const currentInputValue = e.currentTarget.value;
			setHcpId(prevHcps => [...prevHcps, currentInputValue]);
		}
	};

	const handleIconClickFullStudy = () => {
		setLoading(true);
		downloadFullStudy();

	}

	const handleIconClickSite = () => {
		setLoading(true);
		downloadSite();

	}

	const handleIconClickHCP = () => {
		setLoading(true);
		downloadHCP();
	}

	const handleDeleteStudyId = () => {
		setStudyId(null);
	};

	const handleDeleteExcludeSiteId = (siteId: string) => {
		setExcludedSites(prevSites => prevSites.filter(id => id !== siteId));
	};

	const handleDeleteHCPId = (hcpId: string) => {
		setHcpId(prevSites => prevSites.filter(id => id !== hcpId));
	};
	const handleDeleteSiteId = () => {
		setSiteId(null);
	};

	const handleDeleteStudyTwoId = () => {
		setStudyTwoId(null);
	};


	return (
		<>
			{message && <Alert>{message}</Alert>}
			{loading && <Center> <Spinner /> </Center>}
			{!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
			<Divider />
			<Flex direction='column' gap='6' alignItems='flex-start' width='100%'>
				<Title>Full Study Export</Title>
				<InputGroup>
					<Input
						id="studyId"
						placeholder='enter studyID'
						onKeyDown={handleKeyDownStudy}
					/>
					<Input
						id="excludesId"
						placeholder='enter siteId to exclude'
						onKeyDown={handleKeyDownExclude}
					/>
					<InputRightElement>
						<IconButton aria-label="Download" icon={<FontAwesomeIcon color="grey" icon={faDownload} />} onClick={() => handleIconClickFullStudy()} />
					</InputRightElement>
				</InputGroup>

				<Subtitle>Entered ID's:</Subtitle>
				<Flex direction='row' alignItems='flex-start' width='100%' gap="3">
					<EmphasisText>Study ID:</EmphasisText>
					<Text>{studyId
						? (
							<Flex direction="row" align="center">
								<Text>{studyId}</Text>
								<Button onClick={handleDeleteStudyId} variant="ghost" size="xs">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</Flex>
						)
						: <Text>No Study ID entered</Text>
					}
					</Text>
					<EmphasisText>Excluded Site IDs:</EmphasisText>
					<List spacing={1}>
						{excludedSites.map((siteId: string) => (
							<ListItem key={siteId}>
								{siteId}
								<Button onClick={() => handleDeleteExcludeSiteId(siteId)} variant="ghost" size="xs">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</ListItem>
						))}
					</List>
				</Flex>
				<Divider />
				<Title>Site Export</Title>
				<InputGroup>
					<Input
						id="studyId"
						placeholder='enter studyID'
						onChange={handleKeyDownStudyTwo}
					/>
					<Input
						id="siteId"
						placeholder='enter siteId to export'
						onChange={handleKeyDownSingle}
					/>
					<InputRightElement>
						<IconButton aria-label="Download" icon={<FontAwesomeIcon color="grey" icon={faDownload} />} onClick={() => handleIconClickSite()} />
					</InputRightElement>
				</InputGroup>

				<Subtitle>Entered ID's:</Subtitle>
				<Flex direction='row' alignItems='flex-start' width='100%' gap="3">
					<EmphasisText>Study ID:</EmphasisText>
					<Text>{studyTwoId
						? (
							<Flex direction="row" align="center">
								<Text>{studyTwoId}</Text>
								<Button onClick={handleDeleteStudyTwoId} variant="ghost" size="xs">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</Flex>
						)
						: <Text>No Study ID entered</Text>
					}
					</Text>
					<EmphasisText>Site IDs</EmphasisText>
					<Text>{siteId
						? (
							<Flex direction="row" align="center">
								<Text>{siteId}</Text>
								<Button onClick={handleDeleteSiteId} variant="ghost" size="xs">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</Flex>
						)
						: <Text>No Site ID entered</Text>
					}
					</Text>
				</Flex>
				<Divider />
				<Title>HCP Export</Title>
				<InputGroup>
					<Input
						id="hcpId"
						placeholder='enter HCP User ID'
						onKeyDown={handleKeyDownHcp}
					/>
					<InputRightElement>
						<IconButton aria-label="Download" icon={<FontAwesomeIcon color="grey" icon={faDownload} />} onClick={() => handleIconClickHCP()} />
					</InputRightElement>
				</InputGroup>

				<Subtitle>Entered ID's:</Subtitle>
				<Flex direction='row' alignItems='flex-start' width='100%' gap="3">
					<EmphasisText>HCP IDs:</EmphasisText>
					<List spacing={1}>
						{hcpId.map((id: string) => (
							<ListItem key={id}>
								{id}
								<Button onClick={() => handleDeleteHCPId(id)} variant="ghost" size="xs">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</ListItem>
						))}
					</List>

				</Flex>
			</Flex>
			<Divider />


		</>
	);

};

export default DataExports;