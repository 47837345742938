import { Center, Spinner, InputGroup, Input, InputRightElement, Flex, IconButton} from "@chakra-ui/react";
import NeuroventisUsersTable from "../components/neuroventisUsers/NeuroventisUsersTable";
import { ErrorText, NoteText } from "../components/common/Texts";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { get } from "../api/base";

const NeuroventisUsers = () => {
    const [users, setUsers] = useState<any>({helpilepsy:[], migraine:[]});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);


    const search = (value: string, direct: boolean = false, byId: boolean = false) => {
        setLoading(true)
        if(value.length > 0){
            const searchEncoded = encodeURIComponent(value);
            get(`/integration/users/retrieveUsers?direct=${direct}&byId=${byId}&search=${searchEncoded}`)
            .then((response) => {
                if (response.status === 200 ) {
                    const { data } = response.data;
                    setUsers(data);
                    setLoading(false)
                    setError(null)
                }
            })
            .catch((err) => {
                console.error("Error getting users", err.message);
                setError(err.message)
                setLoading(false)
            });
        } else {
            setError(null)
            setUsers({helpilepsy:[], migraine:[]})
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, direct: boolean = false, byId: boolean = false) => {
        if (e.key === 'Enter') {
            setLoading(true);
            search(e.currentTarget.value, direct, byId);
        }
    };

    const handleIconClick = (id: string, direct: boolean = false, byId: boolean = false) => {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element && element.value) {
            setLoading(true);
            search(element.value, direct, byId);
        }
    }
    
    return (
        <>
            <Flex flex='2' gap='2' alignItems='center' flexWrap='wrap' justifyContent='space-between'>
            <InputGroup>
                <Input 
                    id="searchUser"
                    placeholder='Search for a user'
                    onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                    <IconButton aria-label="Search" icon={<FontAwesomeIcon color="grey" icon={faSearch} />} onClick={() => handleIconClick('searchUser')} />
                </InputRightElement>
            </InputGroup>
            <InputGroup>
                <Input 
                    id="searchEmail"
                    placeholder='Search for a specific email'
                    onKeyDown={(e) => handleKeyDown(e, true)}
                />
                <InputRightElement>
                    <IconButton aria-label="Search" icon={<FontAwesomeIcon color="grey" icon={faSearch} />} onClick={() => handleIconClick('searchEmail', true)} />
                </InputRightElement>
            </InputGroup>

            </Flex>
           
            

            
            { loading && <Center> <Spinner /> </Center>}
            {!loading && !error && (users.helpilepsy.length > 0 || users.migraine.length > 0) && <NeuroventisUsersTable users={users} /> }
            {!loading && !error && (users.helpilepsy.length === 0 && users.migraine.length === 0) && <Center><NoteText>No result</NoteText></Center> }
            {!loading && error && <Center><ErrorText>Something went wrong!</ErrorText></Center>}
        </>
    );
};

export default NeuroventisUsers;