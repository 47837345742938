import { useContext } from "react";
import { Card, CardHeader, CardBody, Stack, Heading, StackDivider, Box, Text, CardFooter, Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { AuthContext } from "../services/auth/authContext";
import { useLegacyEffect } from "../utils";

const CoreSingleUser = () => {
    const { user, fetchUser } = useContext(AuthContext);

    useLegacyEffect(() => {
        if(!user){
            const id:string = localStorage.getItem('userId') || "";
            fetchUser && fetchUser(id)
        }
    }, [])
    
    return (
        <Card>
            <CardHeader >
                <Heading size='md'>Core user's profile</Heading>
            </CardHeader>
            
            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            ID
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.id}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Email
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.email}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Full Name
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.firstName+' '+user?.lastName}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Gender
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.gender}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Role
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.admin ? 'Adminnistrator' : 'User'}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Phone
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.phone}
                        </Text>
                    </Box>
                </Stack>
            </CardBody>
            <CardFooter>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='space-between'>
                    {/* <ButtonGroup>
                        <Button colorScheme="blue" onClick={()=>{}}>
                            Reset Password
                        </Button>
                    </ButtonGroup> */}
                    
                </Flex>
            </CardFooter>
        </Card>
    );
};

export default CoreSingleUser;