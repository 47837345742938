import {useContext} from 'react';
import { Box, Center, Spinner } from "@chakra-ui/react";
import { AuthContext } from "./services/auth/authContext";
import Main from "./views/Main";
import AnonymousView from './views/Anonymous';

const App = () => {
  const { isTokenExists, isLoading } = useContext(AuthContext);

  return (
    <Box height="100vh">
      { isLoading && <Center> <Spinner /> </Center>}
      {!isLoading && isTokenExists && <Main /> }
      {!isLoading && !isTokenExists && <AnonymousView />}
    </Box>
  );
};


export default App;
