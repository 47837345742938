import { Card, CardHeader, CardBody, Stack, Heading, StackDivider, Box, Text, Center, Spinner, CardFooter, Button, ButtonGroup, Flex, useDisclosure, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteRequest, get } from "../api/base";
import { ErrorText, NoteText } from "../components/common/Texts";

const CoreSingleOtherUser = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const [user, setUser] = useState<any>();
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
    const modalDelete = useDisclosure()
    const [deleteValidate, setDeleteValidate] = useState<boolean>(false);
	const [deletedUser, setDeletedUser] = useState<boolean>(false);
	const userData = location.state.user;
	useEffect(() => {
		get(`/core/users/singleUser/${userData?.id}`)
		.then((response) => {
			if (response.status === 200 ) {
				const { data } = response.data;
				setUser(data.user);
				setLoading(false)
				setError(null)
			}
		})
		.catch((err) => {
			console.error("Error getting user", err.message);
			setError(err.message)
			setLoading(false)
		});
		
	}, [userData]);

	const validateEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === user?.email) setDeleteValidate(true);
    };

	const deleteUser = () => {
        setLoading(true);
        if(deleteValidate) {
			deleteRequest(`/core/users/${user.id}`)
                .then((response: any) => {
                    if (response.status === 201 ) {
                        setLoading(false);
                        setError(null);
						setDeletedUser(true);
                    }
                })
                .catch((err: any) => {           
                    setError(err.response.data.errors[0].message);
                    setLoading(false)
                });
             
        }else {
            setLoading(false)
            setError(null)
        }
    };

    return (
		<>
			{ loading && <Center> <Spinner /> </Center>}
			{!loading && error && <Center><ErrorText>Something went wrong!</ErrorText></Center>}
			{ !loading && !error &&
				<>
					<Modal blockScrollOnMount={true} isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
					<ModalOverlay />
						<ModalContent>
						<ModalHeader>Delete User</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<InputGroup>
								<Input 
									placeholder={`Enter ${user?.email} to verify`}
									onChange={validateEmail}
								/>
							</InputGroup>
							{!loading && !error && deletedUser && <Center><NoteText>{`User has been deleted`}</NoteText></Center> }
							{!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
						</ModalBody>

						<ModalFooter>
							<Button colorScheme='blue' mr={3} onClick={modalDelete.onClose}>
							Close
							</Button>
							<Button colorScheme='red' onClick={deleteUser}>Delete User</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>

				<Card>
					<CardHeader >
					<Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='space-between'>

						<Heading size='md'>Core user's profile</Heading>
						<ButtonGroup spacing='2'>
							<Button colorScheme="blue" onClick={() => navigate(-1)}>
								Back
							</Button>
                        
                    	</ButtonGroup>
						</Flex>
					</CardHeader>
					
					<CardBody>
						<Stack divider={<StackDivider />} spacing='4'>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									ID
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.id}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Email
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.email}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Full Name
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.firstName+' '+user?.lastName}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Gender
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.gender}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Roles
								</Heading>
								{user?.roles.map((item: any, index: any) => {
									return (
										<Text pt='2' fontSize='sm' key={index}>
											{item}
										</Text>
									)
									
								})}
								
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Phone
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.phone}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Admin
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.admin ? 'Administrator' : 'User'}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Activated
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.activated ? 'Yes' : 'No'}
								</Text>
							</Box>
						</Stack>
					</CardBody>

					<CardFooter>
						<Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='space-between'>
							<ButtonGroup>
								<Button colorScheme="red" onClick={modalDelete.onOpen}>
									Delete User
								</Button>
							</ButtonGroup>
						</Flex>
					</CardFooter>
				</Card> 
				</>
				
			}
		</>
		
        
    );
};

export default CoreSingleOtherUser;