import { Spacer, Spinner, Center } from "@chakra-ui/react";
import ActionsBar from "../components/coreUsers/ActionsBar";
import UsersTable from "../components/coreUsers/UsersTable";
import { ErrorText } from "../components/common/Texts";
import { useFetch } from "../utils";

interface OwnProps {
    data: any, 
    error: string | null, 
    loading: boolean
}

const CoreUsers = () => {
    const { data, error, loading}:OwnProps = useFetch(`/core/users/allUsers?offset=0&limit=10`);
    // const [users, setUsers] = useState<Array<any>>(data ? data.users : []);

    const onSuccessfulInvitation = (newData:any) => {
        data.users.push(newData)
    };

    return (
        <>
            <ActionsBar onSuccesfulInvitation={onSuccessfulInvitation} />
            <Spacer />
            { loading && <Center> <Spinner /> </Center>}
            { !loading && data && !error && <UsersTable users={data.users} />}
            { !loading && !error && <Center> <ErrorText>{error}</ErrorText></Center>}
        </>
    );
};

export default CoreUsers;