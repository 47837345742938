import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';

interface OwnTypes {
    type: string,
    data: Array<Record<string,string>>,
    total: number
}
  
const CountryStats = ({type, data, total}:OwnTypes) => {
    return(
        <Accordion allowToggle>
            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        {type} per country
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <TableContainer>
                        <Table size={'md'}>
                            <Thead>
                            <Tr>
                                <Th textAlign={'center'}>Country</Th>
                                <Th textAlign={'center'}>Users</Th>
                                <Th textAlign={'center'}>Percentage of total</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {data.map((item,i) => {
                                    return(
                                        <Tr key={i}>
                                        <Td textAlign={'center'}>{item?.country || 'other'}</Td>
                                        <Td textAlign={'center'}>{item?.users}</Td>
                                        <Td textAlign={'center'}>{((parseInt(item?.users) / total)*100).toFixed(2)}%</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default CountryStats;