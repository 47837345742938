import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { get } from "../api/base";

const isDevelopmentRun = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const formatTimestamp = (timestamp:string) => (dayjs(timestamp)).format("DD-MMM-YYYY HH:mm");

export const useLegacyEffect = (cb:any, deps:any) => {
    const isMountedRef = useRef(!isDevelopmentRun);
  
    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            return undefined;
        }
    
        return cb();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export const useFetch = (url:string) => {

    const [data,setData] = useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(true)

    useLegacyEffect(() => {

        get(url)
        .then((response) => {
            if (response.status === 200 ) {
                const { data } = response.data;
                setData(data);
                setLoading(false);
            }
        })
        .catch((err) => {
            console.error("Error getting users", err.message);
            setError(err.message)
            setLoading(false);
        });

    }, [url])

    return { data, error, loading }

}

export const versionSort = (arr:Array<any>) => {
    return arr.sort((a,b):any=>{
        if(a.appVersion !== 0 && b.appVersion !== 0){
            a=a.appVersion.split(".");
            b=b.appVersion.split(".");
            return b[0]-a[0] || b[1]-a[1];
        }
    });
}