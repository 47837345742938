import axios from "axios";
import env from '../env.json'

const {REACT_APP_SERVER_URL} = env;

export const get = (endpoint:string) => axios.get(`${REACT_APP_SERVER_URL}${endpoint}`,
    {
        // withCredentials: true,
        headers: { 
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json' 
        }
    }
);

export const post = (endpoint:string, data:Record<string, any>) => { 
    const token = getToken();
    if(token) {
        return axios.post(
            `${REACT_APP_SERVER_URL}${endpoint}`,
            data,
            {
                withCredentials: true,
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }, 
            }
        )
    } else {
        return axios.post(
            `${REACT_APP_SERVER_URL}${endpoint}`,
            data,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json'} 
            }
        )
    }

   };

   export const deleteRequest = (endpoint: string) => { 
    const token = getToken();
    if(token) {
        return axios.delete(
            `${REACT_APP_SERVER_URL}${endpoint}`,
            {
                withCredentials: true,
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }, 
            }
        )
    } else {
        return axios.delete(
            `${REACT_APP_SERVER_URL}${endpoint}`,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json'} 
            }
        )
    }
};



const getToken = () => {
    try {
        let token = localStorage.getItem('authToken');
        return token;
    } catch (e) {
        return null;
    }
};
