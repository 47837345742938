import { createContext, useState, useMemo, useCallback } from "react";
import { loginEmailPassword, fetchCurrentUser, logoutCurrentUser } from ".";
import { post } from "../../api/base";

interface ContextTypes {
  user: Record<string,any> | null,
  isTokenExists: boolean,
  setUser: (req:any) => void,
  isLoading: boolean, 
  fetchUser: (id:string) => void, 
  emailPasswordLogin: (email:string, password:string) => void, 
  logOutUser: () => void, 
  error: Record<string, boolean | string>;
  roles: Array<string>;
}


export const AuthContext = createContext<Partial<ContextTypes>>({});

export const AuthProvider = ({ children }:any) => {
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isTokenExists, setIsTokenExists] = useState<boolean>(() => {
    const token = localStorage.getItem("authToken");
    return token !== null;
  });
  const [user, setUser] = useState<Record<string,any> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Record<string, boolean | string>>({ hasError: false, message: "" });

  const emailPasswordLogin = useCallback( async (email:string, password:string) => {
    setIsLoading(true);
    setError({ hasError: false, message: "" });
    const loginResult = await loginEmailPassword(email, password);
    setIsLoading(false);
    if (loginResult === null || !loginResult.success) {
      if (loginResult && loginResult.result === 'PasswordExpired') {
        setError({ hasError: true, message: "Password Expired" });
        sendRequest(email)
      }
      else
        setError({ hasError: true, message: "Invalid credentials" });
      return ;
    }
    const { data } = loginResult
    localStorage.setItem('authToken', data?.token)
    setIsTokenExists(true);
    localStorage.setItem('roles', JSON.stringify(data?.roles));
    setRoles(data?.roles);
    localStorage.setItem('userId', data?.id)
    fetchUser(data?.id);
  }, []);

  const fetchUser = useCallback( async (id:string) => {
    setError({ hasError: false, message: "" });
    setIsLoading(true);
    const authUserResult = await fetchCurrentUser(id);
    setUser(authUserResult?.data?.user)
    setIsLoading(false);
  },[])

  const logOutUser = useCallback( async () => {
    setIsLoading(true);
    await logoutCurrentUser();
    setIsTokenExists(false);
    setIsLoading(false);
  },[])

  const sendRequest = useCallback((email:string) => {
    setIsLoading(true);
    post("/passwordresets/", { email })
      .then((response:any) => {
        setIsLoading(false);
        if (response.status !== 200) {
          setError({
            hasError: true,
            message: "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
          });
          return;
        }
      })
      .catch((err:any) => {
        setIsLoading(false);
        console.error("Error while sending request", err.message);
        setError({
          hasError: true,
          message: "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
        });
      });
  }, [setIsLoading, setError]);  // add dependencies here

  const userProviderValue = useMemo(() => ({ user, isTokenExists, setIsTokenExists, isLoading, fetchUser, emailPasswordLogin, logOutUser, error, sendRequest, roles, setRoles }), [user, isTokenExists, setIsTokenExists, isLoading, fetchUser, emailPasswordLogin, logOutUser, error, sendRequest, roles, setRoles]);

    return (
      <AuthContext.Provider value={userProviderValue}>
        {children}
      </AuthContext.Provider>
    )
};
