import { useState } from "react";
import { Box, useBreakpointValue, Flex, Divider, Center } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopBar from "../components/common/TopBar";
import CoreUsers from "./CoreUsers";
import CoreSingleUser from "./CoreSingleUser";
import NeuroventisUsers from "./NeuroventisUsers";
import NeuroventisSingleUser from "./NeuroventisSingleUser";
import Statistics from "./Statistics";
import Sidebar from "../components/sidebar";
import NeuroventisStudySingleUser from "./NeuroventisStudySingleUser";
import CoreSingleOtherUser from "./CoreSingleOtherUser";
import NeuroventisSingleUserMoreStats from "./NeuroventisSingleUserMoreStats";
import DataExports from "./DataExports";

const smVariant = { navigation: 'drawer', navigationButton: true }
const mdVariant = { navigation: 'sidebar', navigationButton: false }

const Main = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const variants = useBreakpointValue({ base: smVariant, md: mdVariant })
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
    return(
        <Box height="100vh" p={8} width="100%">
            <Router>
                <TopBar />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box
                        borderRadius="lg"
                        shadow="lg"
                        alignSelf="center"
                        width="100%"
                        p={8}
                    >
                        <Flex>
                            <Sidebar
                                // @ts-ignore
                                variant={variants!.navigation}
                                isOpen={isSidebarOpen}
                                onClose={toggleSidebar}
                            />
                            <Center p="2">
                                <Divider orientation='vertical' variant={'dashed'} colorScheme="blackAlpha" />
                            </Center>
                            <Box w="full">
                                <Routes>
                                    <Route path="/" element={<CoreSingleUser />} />
                                    <Route path="/core_users" element={<CoreUsers />} />
                                    <Route path="/neuroventis_users" element={<NeuroventisUsers />} />
                                    <Route path="/neuroventis_single_user" element={<NeuroventisSingleUser />} />
                                    <Route path="/statistics" element={<Statistics />} />
                                    <Route path="/study_single_user" element ={<NeuroventisStudySingleUser/>} />
                                    <Route path="/neuroventis_single_core_user" element ={<CoreSingleOtherUser/>} />
                                    <Route path="/neuroventis_single_more_stats" element ={<NeuroventisSingleUserMoreStats/>} />
                                    <Route path="/exports" element ={<DataExports/>} />
                                    
                                </Routes>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Router>
        </Box>


    )
}

export default Main;