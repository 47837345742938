import {
    StatGroup,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText
} from '@chakra-ui/react';
import { faPeopleGroup, faCheckCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryStats from './CountryStats';

interface OwnTypes {
    statistics: {
        doctorsAppVersion: Array<any>,
        doctorsAppVersionActive: Array<any>,
        doctorsCountry: Array<any>,
        doctorsCountryActive: Array<any>,
        totalDoctors: number,
        totalDoctorsActive: number
    }
}

const DoctorStatistics = ({statistics}:OwnTypes) => {
    return(
        <>
            <StatGroup>
                <Stat textAlign={'center'}>
                    <StatLabel>Total doctors</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faPeopleGroup} /> {statistics?.totalDoctors}</StatNumber>
                </Stat>

                <Stat textAlign={'center'}>
                    <StatLabel>Total active doctors</StatLabel>
                    <StatNumber><FontAwesomeIcon icon={faCheckCircle} color='green' /> {statistics?.totalDoctorsActive}</StatNumber>
                    <StatHelpText>
                        <FontAwesomeIcon icon={faChartLine} color='orange' /> {((statistics?.totalDoctorsActive / statistics?.totalDoctors) * 100).toFixed(2)} %
                    </StatHelpText>
                </Stat>
            </StatGroup>
            <CountryStats type={'Doctors'} data={statistics?.doctorsCountry} total={statistics?.totalDoctors}/>
            <CountryStats type={'Active doctors'} data={statistics?.doctorsCountryActive} total={statistics?.totalDoctorsActive}/>
        </>
    )
}

export default DoctorStatistics;