import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Center,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { versionSort } from '../../utils';

interface OwnTypes {
    type: string,
    data: Array<Record<string,string>>,
    total: number
}
  
const AppVersion = ({type, data, total}:OwnTypes) => {
    const sortedData = versionSort(data);
    return(
        <Accordion allowToggle>
            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        {type} per version
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <TableContainer>
                        <Table size={'md'}>
                            <Thead>
                            <Tr>
                                <Th textAlign={'center'}>Version</Th>
                                <Th textAlign={'center'}>Users</Th>
                                <Th textAlign={'center'}>Percentage of total</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {sortedData.map((item,i) => (
                                    <Tr key={i}>
                                    <Td textAlign={'center'}>{item?.appVersion}</Td>
                                    <Td textAlign={'center'}>{item?.users}</Td>
                                    <Td textAlign={'center'}>{((parseInt(item?.users) / total)*100).toFixed(2)}%</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default AppVersion;