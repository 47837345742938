import { Text } from "@chakra-ui/react";

export const LinkText = ({ children }:any) => (
  <Text textColor="blue.500">{children}</Text>
);

export const EmphasisText = ({ children, ...extraParams }:any) => (
  <Text {...extraParams} fontWeight="extrabold">
    {children}
  </Text>
);

export const ErrorText = ({ children }:any) => (
  <Text textColor="red.600">{children}</Text>
);

export const Subtitle = ({ children }:any) => (
  <Text fontSize="2xl">{children}</Text>
);

export const Title = ({ children }:any) => (
  <Text fontSize="3xl">{children}</Text>
);

export const NoteText = ({ children }:any) => <Text fontSize="sm" textColor="gray.700">{children}</Text>;
