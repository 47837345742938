import { Card, CardHeader, CardBody, Stack, Heading, StackDivider, Box, Text, Button, ButtonGroup, Flex, Center, Divider, Table, TableContainer, Tbody, Th, Thead, Tr, Td, Spinner, } from "@chakra-ui/react";
import { ErrorText, Subtitle } from "../components/common/Texts";
import { useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import { get } from "../api/base";

const NeuroventisSingleUserMoreStats = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = location.state.user;
	const [user, setUser] = useState<any>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		get(`/integration/users/userDetails/${userData?.id}?app=${userData?.app}&type=${userData?.role}`)
		.then((response) => {
			if (response.status === 200 ) {
				const { data } = response.data;
				Object.keys(data?.patient).length === 0 ? setUser(data.doctor) : setUser(data.patient);
				setLoading(false)
				setError(null)
			}
		})
		.catch((err) => {
			console.error("Error getting user", err.message);
			setError(err.message)
			setLoading(false)
		});
	}, [userData]);

    return (
       <>
	   
         <Card>
            <CardHeader >
            <Heading size='md'>{userData?.role} - {userData?.email}</Heading>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='flex-end'>
                    <ButtonGroup spacing='2'>
                        <Button colorScheme="blue" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </ButtonGroup>
                </Flex>
            </CardHeader>
            {loading && !error && <Center><Spinner/></Center>}
			{!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
            <CardBody>
				{
					userData?.role === 'patient' && 
					<>
						<Stack divider={<StackDivider />} spacing='4'>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									ID
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.id}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Email
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.email}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Birthday
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.birthday}
								</Text>
							</Box>
							<Box>
								<Heading size='xs' textTransform='uppercase'>
									Gender
								</Heading>
								<Text pt='2' fontSize='sm'>
									{user?.gender}
								</Text>
							</Box>
						</Stack>
						<Divider height={'10px'}/>
		
						<Subtitle>Linked Doctors</Subtitle>
							<TableContainer>
								<Table>
									<Thead>
										<Tr>
											<Th>id</Th>
											<Th>Email</Th>
										</Tr>
									</Thead>
										<Tbody>
										{user?.linkedDoctors?.map((item: any, index: any) => (
											<Tr key={index} _hover={{ bg: 'gray.200' }} cursor={'pointer'} onClick={() => navigate('/neuroventis_single_user', {state: {id: item?.id, email: item?.email}})}>
												<Td>{item?.id}</Td>
												<Td>{item?.email}</Td>
											</Tr>
										))}
										</Tbody>
								</Table>
							</TableContainer>
			
					</>
				}
				{
					userData?.role === 'doctor' && 
					<>
						<Subtitle>Linked Patients</Subtitle>
							<TableContainer>
							<Table>
								<Thead>
								<Tr>
									<Th>id</Th>
									<Th>Email</Th>
									<Th>App Installed</Th>
								</Tr>
								</Thead>
								<Tbody>
								{user?.linkedPatients?.map((item: any, index: any) => (
									<Tr key={index} onClick={() => navigate('/neuroventis_single_user', {state: {id: item?.id, email: item?.email}})}>
										<Td>{item?.id}</Td>
										<Td>{item?.email}</Td>
										<Td>{item?.appStillInstalled}</Td>
									</Tr>
								))}
								</Tbody>
							</Table>
							</TableContainer>
				
					</>
				}

            </CardBody>
          
        </Card>
       </>
       
    );
};

export default NeuroventisSingleUserMoreStats;