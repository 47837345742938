import { Card, CardHeader, CardBody, Stack, Heading, StackDivider, Box, Text, Button, ButtonGroup, Flex, CardFooter, Modal, ModalOverlay, ModalContent,ModalHeader,ModalFooter,ModalBody,ModalCloseButton, useDisclosure, InputGroup, Input, Center, Link, } from "@chakra-ui/react";
import { EmphasisText, ErrorText, NoteText } from "../components/common/Texts";
import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import { get, post } from "../api/base";

interface User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    role: string;
    deleted: boolean;
    consent: boolean;
    lastActivity: string;
    createdAt: string;
    appVersion: string;
    app: string;
    // Add other fields if they exist
}

const NeuroventisSingleUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState<User | null>(null);
    const userData = location.state.user;
    const extraData = location.state.email;
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteValidate, setDeleteValidate] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<null>();
    const [updateValidate, setUpdateValidate] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState<string>('')
    const [updateMessage, setUpdateMessage] = useState<boolean>(false);
    const [resetLink, setResetLink] = useState<string>('')
    const modalDelete = useDisclosure()
    const modalUpdateEmail = useDisclosure();
    const alertResetPass = useDisclosure();

    useEffect(() => {
        if(userData) {
            setUser(userData);
        }
        if(extraData) {
            get(`/integration/users/retrieveUsers?direct=true&byId=false&search=${encodeURIComponent(extraData)}`)
                .then((response) => {
                    if (response.status === 200 ) {
                        
                        const { data } = response.data;
                        data.helpilepsy.length > 0 ? setUser(data.helpilepsy[0]) : setUser(data.migraine[0]);
                        
                        setLoading(false)
                        setError(null)
                    }
                })
                .catch((err) => {
                    console.error("Error getting users", err.message);
                    setError(err.message)
                    setLoading(false)
                });
        }

    }, [userData, extraData])

    const validateEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === user?.email) setDeleteValidate(true);
    };

    const validateUpdateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value !== user?.email)  {
            setUpdateValidate(true);
            setNewEmail(e.target.value);
        }
        
    };

    const deleteUser = () => {
        setLoading(true);
        if(deleteValidate) {
                post(`/integration/users/deletePatient?app=${user?.app}`, {email: user?.email })
                .then((response: any) => {
                    if (response.status === 201 ) {
                        const { data } = response.data;
                        setDeleteMessage(data.email);
                        setLoading(false)
                        setError(null)
                    }
                })
                .catch((err: any) => {           
                    setError(err.response.data.errors[0].message);
                    setLoading(false)
                });
             
        }else {
            setLoading(false)
            setError(null)
        }
    };

    const updateEmail = () => {
        setLoading(true);
  
        if(updateValidate && newEmail.length > 0) {
                post(`/integration/users/updatePatientEmail?app=${user?.app}`, {oldEmail: user?.email, newEmail: newEmail})
                .then((response: any) => {
                    if (response.status === 201 ) {
                        const { data } = response.data;
                        setUpdateMessage(data.newEmail);
                        setLoading(false)
                        setError(null)
                    }
                })
                .catch((err: any) => {           
                    setError(err.response.data.errors[0].message);
                    setLoading(false)
                });
             
        }else {
            setLoading(false)
            setError(null)
        }
    };

    const generateReset = () => {
        setLoading(true);
        if(user?.email) {
            post(`/integration/users/resetPaswordPatient?app=${user?.app}`, {email: user?.email })
            .then((response: any) => {
                if (response.status === 201 ) {
                    const { data } = response.data;
                    setResetLink(data.resetUrl);
                    setLoading(false)
                    setError(null)
                }
            })
            .catch((err: any) => {           
                setError(err.response.data.errors[0].message);
                setLoading(false)
            });
         
        } else {
            setLoading(false)
            setError(null)
        }
    }

    return (
       <>
            <Modal blockScrollOnMount={true} isOpen={alertResetPass.isOpen} onClose={alertResetPass.onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Reset password</ModalHeader>
              <ModalCloseButton />
              <ModalBody minWidth={'24'} height={'24'}>
                  {!loading && !error && resetLink.length <= 0 && <><Center><EmphasisText> By clicking the "Ok" button you are generating a reset link.</EmphasisText></Center></>}
                 
                  {!loading && !error && resetLink.length > 0 && <Link fontWeight={'semibold'} color="blue" isExternal href={resetLink}>{resetLink}</Link> }
                  {!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
              </ModalBody>
    
              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={alertResetPass.onClose}>
                  Close
                </Button>
                <Button colorScheme='green' onClick={generateReset}>Ok</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

            <Modal blockScrollOnMount={true} isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Delete User</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                  <InputGroup>
                    <Input 
                        placeholder={`Enter ${user?.email} to verify`}
                        onChange={validateEmail}
                    />
                  </InputGroup>
                  {!loading && !error && deleteMessage && <Center><NoteText>{`${deleteMessage} has been deleted`}</NoteText></Center> }
                  {!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
              </ModalBody>
    
              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={modalDelete.onClose}>
                  Close
                </Button>
                <Button colorScheme='red' onClick={deleteUser}>Delete User</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal blockScrollOnMount={true} isOpen={modalUpdateEmail.isOpen} onClose={modalUpdateEmail.onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Email</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                  <InputGroup flex='1' gap='2' alignItems='center' flexWrap='wrap' justifyContent='space-between'>
                    <Text mb='8px' fontWeight={'semibold'}>Updating email: {user?.email}</Text>
                    <Input 
                        placeholder={`Enter new Email`}
                        onChange={validateUpdateEmail}
                    />
                  </InputGroup>
                  {!loading && !error && updateMessage && <Center><NoteText>{`email updated to: ${updateEmail}`}</NoteText></Center> }
                  {!loading && error && <Center><ErrorText>{error}</ErrorText></Center>}
              </ModalBody>
    
              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={modalUpdateEmail.onClose}>
                  Close
                </Button>
                <Button colorScheme='orange' onClick={updateEmail}>Update email</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        
         <Card>
            <CardHeader >
            <Heading size='md'>User's profile</Heading>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='flex-end'>
                    

                    <ButtonGroup spacing='2'>
                        <Button colorScheme="blue" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup spacing='2'>
                        <Button colorScheme="green" onClick={() => navigate('/neuroventis_single_more_stats', {state: {user}})}>
                            More info
                        </Button>
                    </ButtonGroup>
                
                </Flex>
            </CardHeader>
            
            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            ID
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.id}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Email
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.email}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Full Name
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.firstName+' '+user?.lastName}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Language
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.language}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Role
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.role}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Deleted
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.deleted === false ? 'no' : 'yes'}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Consent
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.consent === false ? 'no' : 'yes'}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Last activity
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.lastActivity}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Account Created At
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.createdAt}
                        </Text>
                    </Box>
                   
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            App version
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.appVersion}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Application
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {user?.app}
                        </Text>
                    </Box>
                </Stack>
                
            </CardBody>
            <CardFooter>
            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' justifyContent='space-between'>
                    <ButtonGroup>
                        <Button colorScheme="red" onClick={modalDelete.onOpen}>
                            Delete User
                        </Button>
                        <Button colorScheme="orange" onClick={modalUpdateEmail.onOpen}>
                            Update Email
                        </Button>
                        <Button colorScheme="blue" onClick={alertResetPass.onOpen}>
                            Reset Password
                        </Button>
                    </ButtonGroup>

                   
                </Flex>
            </CardFooter>
        </Card>
       </>
       
    );
};

export default NeuroventisSingleUser;