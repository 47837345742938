import React, { useRef } from "react";
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import ModalDialog from "../common/ModalDialog";
import InvitationForm from "./InvitationForm";
import { post } from "../../api/base";

interface OwnTypes {
    onSuccesfulInvitation: (res:any) => void
}

const ActionsBar = ({ onSuccesfulInvitation }:OwnTypes) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const invitationValuesRef = useRef<Array<any>>([]);
  const toast = useToast();

  const sendInvitation = () => {
    console.log("Sending invitation to", invitationValuesRef.current[0]);
    if (!invitationValuesRef.current) {
      return;
    }
    console.log(invitationValuesRef.current[3])

    post("/core/auth/invite", { 
            email: invitationValuesRef.current[0] as string,
            password: invitationValuesRef.current[1] as string,
            firstName: invitationValuesRef.current[2] as string,
            lastName: invitationValuesRef.current[3] as string,
            phone: invitationValuesRef.current[4] as string,
            gender: invitationValuesRef.current[5] as string,
            roles: invitationValuesRef.current[6] as [string]
        }
    )
      .then((response) => {
        if (response.status === 201) {
          toast({
            title: "Invitation created.",
            description: "Invitation has been sent to the person",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onSuccesfulInvitation(response.data.result);
        } else {
          toast({
            title: "Error inviting person.",
            description: `An error has occurred inviting the person: ${response.data.result}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        onClose();
      })
      .catch((err) => {
        console.error("Error creating invitation:", err.message);
        toast({
          title: "Error inviting person.",
          description: `An error has occurred inviting the person: ${err.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <ModalDialog
        title="Invite Person to Study Dashboard"
        isOpen={isOpen}
        onClose={onClose}
        onMainAction={sendInvitation}
        buttonText="Invite person"
      >
        <InvitationForm valuesRef={invitationValuesRef} />
      </ModalDialog>
      <Box marginY={4}>
        <Button colorScheme="blue" onClick={onOpen}>
          Invite Person
        </Button>
      </Box>
    </>
  );
};

export default ActionsBar;